.App {
  height: 95vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Loading {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.gear {
  width: 15rem;
  height: 15rem;
}

footer {
  width: 80%;
  position: fixed;
  left: 10%;
  bottom: 2rem;
  text-align: center;
}

footer a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

@media (max-width: 600px) {
  .App {
    height: 85vh;
    margin: 0 2rem;
    flex-direction: column;
    justify-content: center;
  }

  .gear {
    width: 10rem;
    height: 10rem;
  }

  .text {
    margin-top: 2rem;
    text-align: center;
  }

  .text h1 {
    font-size: x-large;
  }

  .text h2 {
    font-size: large;
  }
}
